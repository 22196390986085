import getConfig from 'next/config'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const {
  POSTMARK_KEY,
  ALGOLIA_ADMIN_KEY,
  SANITY_AUTH_TOKEN,
  SHOPIFY_WEBHOOK_SECRET,
} = serverRuntimeConfig
const {
  SANITY_PROJECT_ID,
  SANITY_DATASET,
  SANITY_READ_TOKEN,
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY,
  SENTRY_DSN,
  SITE_NAME,
  STOREFRONT_ENV,
  SHOPIFY_STOREFRONT_TOKEN,
  SHOPIFY_SHOP_NAME,
  HUBSPOT_PORTAL_ID,
  GA_TRACKING_ID,
  SHOPIFY_VENDORS_TO_SYNC,
  SHOPIFY_COLLECTIONS_TO_SYNC,
  SHOPIFY_TAGS_TO_SYNC,
  SHOPIFY_CHECKOUT_DOMAIN,
  FB_PIXEL,
} = publicRuntimeConfig

const ALGOLIA_API_KEY =
  typeof window === 'undefined' ? ALGOLIA_ADMIN_KEY : ALGOLIA_SEARCH_KEY

if (typeof window !== 'undefined' && ALGOLIA_ADMIN_KEY) {
  throw new Error('You must not provide the browser with the algolia admin key')
}
if (typeof window !== 'undefined' && POSTMARK_KEY) {
  throw new Error('You must not provide the browser with the postmark key')
}
if (typeof window !== 'undefined' && SANITY_AUTH_TOKEN) {
  throw new Error('You must not provide the browser with the SANITY_AUTH_TOKEN')
}
if (!SHOPIFY_CHECKOUT_DOMAIN)
  throw new Error('You must provide a storefront checkout domain')

if (!SANITY_PROJECT_ID)
  throw new Error('You must include a SANITY_PROJECT_ID variable')
if (!SANITY_DATASET)
  throw new Error('You must include a SANITY_DATASET variable')
// if (!ALGOLIA_API_KEY) throw new Error('You must provide an algolia API Key')
// if (!ALGOLIA_APP_ID) throw new Error('You must provide an algolia app ID')
if (!SENTRY_DSN) throw new Error('You must provide a sentry DSN')
// if (!STOREFRONT_ENV) throw new Error('You must provide a storefront ENV')

const SHOPIFY_STOREFRONT_URL = `https://${SHOPIFY_CHECKOUT_DOMAIN}/api/graphql`
const SANITY_GRAPHQL_URL = `https://${SANITY_PROJECT_ID}.api.sanity.io/v1/graphql/${SANITY_DATASET}/default`

export const config = {
  ALGOLIA_API_KEY,
  ALGOLIA_APP_ID,
  GA_TRACKING_ID,
  STOREFRONT_ENV,
  SHOPIFY_STOREFRONT_TOKEN,
  SHOPIFY_STOREFRONT_URL,
  SHOPIFY_VENDORS_TO_SYNC,
  SHOPIFY_COLLECTIONS_TO_SYNC,
  SHOPIFY_TAGS_TO_SYNC,
  SHOPIFY_WEBHOOK_SECRET,
  SHOPIFY_CHECKOUT_DOMAIN,
  SANITY_PROJECT_ID,
  SANITY_DATASET,
  SANITY_READ_TOKEN,
  SANITY_GRAPHQL_URL,
  SANITY_AUTH_TOKEN,
  SHOPIFY_SHOP_NAME,
  POSTMARK_KEY,
  HUBSPOT_PORTAL_ID,
  SENTRY_DSN,
  SITE_NAME,
  FB_PIXEL,
}
